<template>
  <v-app>
    <div>
      <b-modal
        id="add-sub"
        hide-footer
        :title="'Tambah Sub Pasien -' + patientName"
        size="lg"
      >
        <b-form @submit.stop.prevent="formOnSubmit">
          <div class="row">
            <div class="col-md-6">
              <!-- Input Name -->
              <b-form-group
                id="input-group-name"
                label="Nama:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Nama Lengkap"
                >
                </b-form-input>
                <small class="text-danger">{{ error.name }}</small>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                id="input-group-birth-date"
              >
              <label for="born-date">Tanggal Lahir: <em class="text-muted">opsional</em></label>
                <b-input-group>
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.birt_date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.birt_date"
                          placeholder="Tanggal Lahir (YYYY-MM-DD)"
                          @input="formaterDate"
                        >
                        </b-form-input>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.birt_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.birt_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <small class="text-danger">{{ error.birt_date }}</small>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-12 pt-0">
              <!-- Input Notes -->
              <b-form-group
                id="input-group-notes"
                
              >
               <label for="born-date">Deskripsi: <em class="text-muted">opsional</em></label>
                <b-form-textarea
                  id="input-notes"
                  v-model="form.notes"
                  rows="3"
                  placeholder="Catatan"
                ></b-form-textarea>
                <small class="text-danger">{{ error.notes}}</small>
              </b-form-group>
            </div>
          </div>

          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="hideModal"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
export default {
  name: "Form",

  props: {
    patientId: Number,
    patientName: String,
    form: Object,
  },

  data() {
    return {
      error: {
        name: "",
        birt_date: "",
        notes: "",
      },
      
      // date
      time: null,
      menu2: false,
      modal2: false,
      picker: new Date().toISOString().substr(0, 10),

      // other
      dataLoad: false,
      show: true,
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.birt_date = this.form.birt_date + "-";
      }
      if (val.length == 7) {
        this.form.birt_date = this.form.birt_date + "-";
      }
    },

    hideModal() {
      this.form = [];
      this.$bvModal.hide("add-sub");
    },

    async formOnSubmit() {
      this.form.patient_id = this.patientId;

      console.log(this.form);
      // Make Request
      let response = await module.submit(this.form, this.form.endPoint);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.hideModal();

        this.$root.$emit('updateSubPatient')
      }
    },
  },

  mounted() {
    // this.patient();
    // console.log(this.form);
  },
};
</script>

